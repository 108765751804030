import React, { useState, useEffect } from "react";
import Logo from "../assets/pizzaLogo.png";
import PizzaLeft from "../assets/gradient.jpeg";
// import { Link } from "react-router-dom";
// import Logo2 from "../assets/bitcoin_logo_2222.svg";
import {HashLink as Link} from 'react-router-hash-link';
// import ReorderIcon from "@material-ui/icons/Reorder";
import CloseIcon from '@mui/icons-material/Close';
import "../styles/Navbar2.css";

function Navbar2() {

  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [checked, setChecked] = useState(false);

  const handleCheck =() => {
    setChecked(!checked)
   }
  

const controlNavbar = () => {
  if (typeof window !== 'undefined') { 
    if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
      setShow(true); 
    } else { // if scroll up show the navbar
      setShow(false);  
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY); 
  }
};

useEffect(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', controlNavbar);
    // cleanup function
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }
}, [lastScrollY]);




  return (
    <div >
    <div >
    <nav >
      <div className={`navbar2 active2 ${show && 'hidden2'}`}>
        <div className="container nav-container">
          {/* <div className="logo">
          <img className="logo" src={Logo} />
            <h3 style={{color: '#023e8a'}}>YOUR</h3>
            <h3 style={{color: '#4cc9f0'}}>LOGO</h3>
            <h3 style={{color: '#ffd166'}}>HERE</h3>
          </div> */}
          <div className='title'>
            <h3>Name/Logo</h3>
          </div>
            <input className="checkbox" type="checkbox" 
            // name="" 
            // id="checkclick" 
            checked={checked} 
            onClick={handleCheck}
            />
            <div className="hamburger-lines" onClick={handleCheck}>
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>  
          <div className="menu-items">
            <li onClick={handleCheck}><a href="https://www.soundsummoner.com">Home</a></li>
            <li onClick={handleCheck}><Link smooth to="#menu">Menu/Drink Specials</Link></li>
            <li onClick={handleCheck}><a href="https://gallery.soundsummoner.com">Gallery</a></li>
            <li onClick={handleCheck}><a href="https://about.soundsummoner.com">About</a></li>

            {/* <li onClick={handleCheck}><Link smooth to="#podcast">Event/Calendar</Link></li> */}
            <li onClick={handleCheck}><Link smooth to="#contact">Contact</Link></li>
            <li onClick={handleCheck}><Link smooth to="#contact">Other Ideas:</Link></li>
            <li onClick={handleCheck}><Link smooth to="#contact">Meet The Staff</Link></li>
            
            
          </div>
        </div>
      </div>
    </nav>
  </div>

  </div>
  );
}

export default Navbar2;